import { Component, OnInit } from "@angular/core";
import * as seo_data from "../../meta_tag_info.json";
import { Meta, Title } from "@angular/platform-browser";
const dy_data = (<any>seo_data).about;
declare var $: any;
// import * as $ from 'jquery';

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.css"],
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class AboutComponent implements OnInit {
  public screenWidth: any;
  public teamCardWidth: any;

  constructor(private _title: Title, private _meta: Meta) {
    // $(function () {
    //   //   $('.marquee').marquee({
    //   //     duration: 5000,
    //   //     duplicated: true,
    //   //     gap: 0,
    //   //     direction: 'left',
    //   //     pauseOnHover: true
    //   //   });

    //   $('.marquee').slick({
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 3
    //   });

    // });
  }

  ngOnInit() {
    this._title.setTitle(dy_data.title);
    this._meta.updateTag({
      name: dy_data.meta_info.desc.name,
      content: dy_data.meta_info.desc.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.keywords.name,
      content: dy_data.meta_info.keywords.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.robots.name,
      content: dy_data.meta_info.robots.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.tc.name,
      content: dy_data.meta_info.tc.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.td.name,
      content: dy_data.meta_info.td.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.tt.name,
      content: dy_data.meta_info.tt.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.ti.name,
      content: dy_data.meta_info.ti.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogl.property,
      content: dy_data.meta_info.ogl.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogt.property,
      content: dy_data.meta_info.ogt.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogti.property,
      content: dy_data.meta_info.ogti.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogd.property,
      content: dy_data.meta_info.ogd.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogu.property,
      content: dy_data.meta_info.ogu.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogi.property,
      content: dy_data.meta_info.ogi.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogim.property,
      content: dy_data.meta_info.ogim.content,
    });

    this.screenWidth = window.innerWidth - 570;
    this.teamCardWidth = this.screenWidth / 4;
  }

  onResize(event) {
    this.screenWidth = event.target.innerWidth - 570;
    this.teamCardWidth = this.screenWidth / 4; // window width
  }

  applyStyles() {
    const styles = { width: this.teamCardWidth + "px" };
    return styles;
  }
}
