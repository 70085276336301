import { Component, OnInit } from "@angular/core";
import { UserService } from "../../_services/user.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective
} from "@angular/forms";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  contactforms;
  emailRegex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
  phoneRegex = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
  // phoneRegex = /^\d{9}$/;
  nameRegex = /^[a-z ,.'-]+$/i;
  formResponse = "";
  loading = false;
  switch = 1;
  cur_year = 0;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.cur_year = new Date().getFullYear();
    // contact form
    this.contactforms = new FormGroup({
      radio: new FormControl("", Validators.compose([Validators.required])),
      name: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.nameRegex)
        ])
      ),
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailRegex)
        ])
      ),
      mobile: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.phoneRegex)
        ])
      ),
      text: new FormControl("")
    });
  }

  get f() {
    return this.contactforms.controls;
  }

  onValidValue(id) {
    console.log("valid value in form", id);
    // document.getElementById(id).setAttribute('class', 'form-control.is-valid');
    // console.log(el);
  }

  contactFormData(dataToSend) {
    this.loading = true;
    // console.log('contact form data', dataToSend);
    dataToSend["radio-group"] = dataToSend["radio"];
    delete dataToSend["radio"];
    // console.log('contact form data', dataToSend);

    this.userService.contactform(dataToSend).subscribe(
      data => {
        // console.log('contact form on submit', data);
        this.formResponse = "Success";
        this.loading = false;
        document.getElementById("simple-msg").style.color = "green";
      },
      error => {
        this.formResponse = "Error";
        this.loading = false;
        document.getElementById("simple-msg").style.color = "red";
      }
    );
  }
}
