import { Component, OnInit } from "@angular/core";
import * as seo_data from "../../meta_tag_info.json";
import { Meta, Title } from "@angular/platform-browser";
const dy_data = (<any>seo_data).healthcare_providers;

@Component({
  selector: "app-healthcare-providers",
  templateUrl: "./healthcare-providers.component.html",
  styleUrls: ["./healthcare-providers.component.css"],
})
export class HealthcareProvidersComponent implements OnInit {
  constructor(private _title: Title, private _meta: Meta) {}

  ngOnInit() {
    this._title.setTitle(dy_data.title);
    this._meta.updateTag({
      name: dy_data.meta_info.desc.name,
      content: dy_data.meta_info.desc.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.keywords.name,
      content: dy_data.meta_info.keywords.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.robots.name,
      content: dy_data.meta_info.robots.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.tc.name,
      content: dy_data.meta_info.tc.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.td.name,
      content: dy_data.meta_info.td.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.tt.name,
      content: dy_data.meta_info.tt.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.ti.name,
      content: dy_data.meta_info.ti.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogl.property,
      content: dy_data.meta_info.ogl.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogt.property,
      content: dy_data.meta_info.ogt.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogti.property,
      content: dy_data.meta_info.ogti.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogd.property,
      content: dy_data.meta_info.ogd.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogu.property,
      content: dy_data.meta_info.ogu.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogi.property,
      content: dy_data.meta_info.ogi.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogim.property,
      content: dy_data.meta_info.ogim.content,
    });
  }
}
