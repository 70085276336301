import { Component, OnInit } from "@angular/core";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  Event,
  NavigationStart,
  RoutesRecognized,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { ScrollSpyService } from "ngx-scrollspy";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class HeaderComponent implements OnInit {
  navbarOpen = false;
  public screenWidth: any;

  constructor(
    private scrollSpyService: ScrollSpyService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  toggleMenu() {
    document.getElementById("navbarCollapse").classList.toggle("show");
    document.getElementById("navbarCollapse").classList.toggle("collapsed");
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 999) {
      // document.getElementById("navbarCollapse").classList.toggle("show");
      this.navbarOpen = false;
    } else {
      // document.getElementById("navbarCollapse").classList.toggle("collapsed");
      this.navbarOpen = true;
    }
  }

  onResize(event) {
    this.screenWidth = event.target.innerWidth;
    if (this.screenWidth > 999) {
      // document.getElementById("navbarCollapse").classList.toggle("show");
      this.navbarOpen = false;
    } else {
      // document.getElementById("navbarCollapse").classList.toggle("collapsed");
      this.navbarOpen = true;
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.clearMenu("#434343");
        if (this.router.url == "/") {
          document
            .querySelector('a[data="id_patient"]')
            .setAttribute("style", "color:#006edb !important");
        }
        if (this.router.url == "/about") {
          document
            .querySelector('a[data="id_about"]')
            .setAttribute("style", "color:#006edb !important");
        }
        if (this.router.url == "/healthcare-provider") {
          document
            .querySelector('a[data="id_healthcare_providers"]')
            .setAttribute("style", "color:#006edb !important");
        }
        if (this.router.url == "/telemedicine") {
          document
            .querySelector('a[data="id_telemedicine"]')
            .setAttribute("style", "color:#006edb !important");
        }
        if (this.router.url == "/blogs") {
          document
            .querySelector('a[data="id_blogs"]')
            .setAttribute("style", "color:#006edb !important");
        }
        if (this.router.url == "/impact") {
          document
            .querySelector('a[data="id_impact"]')
            .setAttribute("style", "color:#006edb !important");
        }
      }
    });
  }

  clearMenu(color) {
    document
      .querySelector('a[data="id_patient"]')
      .setAttribute("style", "color:" + color + "!important");
    document
      .querySelector('a[data="id_healthcare_providers"]')
      .setAttribute("style", "color:" + color + "!important");
    document
      .querySelector('a[data="id_telemedicine"]')
      .setAttribute("style", "color:" + color + "!important");
    document
      .querySelector('a[data="id_about"]')
      .setAttribute("style", "color:" + color + "!important");
    document
      .querySelector('a[data="id_impact"]')
      .setAttribute("style", "color:" + color + "!important");
    document
      .querySelector('a[data="id_blogs"]')
      .setAttribute("style", "color:" + color + "!important");
    // document
    //   .querySelector('a[data="id_careers"]')
    //   .setAttribute("style", "color:" + color + "!important");
    document
      .querySelector('a[data="id_contactus"]')
      .setAttribute("style", "color:" + color + "!important");
  }
}
