import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { AboutComponent } from "./pages/about/about.component";
import { HealthcareProvidersComponent } from "./pages/healthcare-providers/healthcare-providers.component";
import { TelemedicineComponent } from "./pages/telemedicine/telemedicine.component";
import { BlogsComponent } from "./pages/blogs/blogs.component";
import { ImpactComponent } from "./pages/impact/impact.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { Blog1Component } from "./pages/blogs/blog1/blog1.component";
import { Blog2Component } from "./pages/blogs/blog2/blog2.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { Blog3Component } from "./pages/blogs/blog3/blog3.component";
import { Blog4Component } from "./pages/blogs/blog4/blog4.component";
import { Blog5Component } from "./pages/blogs/blog5/blog5.component";
import { Blog6Component } from "./pages/blogs/blog6/blog6.component";
import { Blog7Component } from "./pages/blogs/blog7/blog7.component";
import { Blog8Component } from "./pages/blogs/blog8/blog8.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "healthcare-provider", component: HealthcareProvidersComponent },
  { path: "telemedicine", component: TelemedicineComponent },
  { path: "blogs", component: BlogsComponent },
  { path: "impact", component: ImpactComponent },
  { path: "policy", component: PrivacyPolicyComponent },
  { path: "terms", component: TermsComponent },
  { path: "faq", component: FaqComponent },
  {
    path: "blogs/blood-glucose-levels-morning-variability",
    component: Blog1Component,
  },
  {
    path: "blogs/neuro-bayesian-ai-for-diabetes-care",
    component: Blog2Component,
  },
  {
    path: "blogs/steps-to-not-gain-weight-in-a-lockdown!",
    component: Blog3Component,
  },
  {
    path: "blogs/fasting-and-its-impact-on-blood-sugar-levels",
    component: Blog4Component,
  },
  {
    path: "blogs/health-risks-with-abdominal-obesity",
    component: Blog5Component,
  },
  {
    path: "blogs/importance-of-pseudocereals",
    component: Blog6Component,
  },
  {
    path: "blogs/ramzan-and-diabetes",
    component: Blog7Component,
  },
  {
    path: "blogs/covid-19-and-diabetes",
    component: Blog8Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
