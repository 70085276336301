import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { HeaderComponent } from "./layout/header/header.component";
import { HomeComponent } from "./pages/home/home.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
} from "@angular/material";
import { AboutComponent } from "./pages/about/about.component";
import { HealthcareProvidersComponent } from "./pages/healthcare-providers/healthcare-providers.component";
import { TelemedicineComponent } from "./pages/telemedicine/telemedicine.component";
import { BlogsComponent } from "./pages/blogs/blogs.component";
import { NgbModule, NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { ScrollSpyModule } from "ngx-scrollspy";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { RouterModule } from "@angular/router";
import { ImpactComponent } from "./pages/impact/impact.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { Blog1Component } from "./pages/blogs/blog1/blog1.component";
import { Blog2Component } from "./pages/blogs/blog2/blog2.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { Blog3Component } from "./pages/blogs/blog3/blog3.component";
import { Blog4Component } from "./pages/blogs/blog4/blog4.component";
import { Blog5Component } from "./pages/blogs/blog5/blog5.component";
import { Blog6Component } from "./pages/blogs/blog6/blog6.component";
import { Blog7Component } from "./pages/blogs/blog7/blog7.component";
import { Blog8Component } from "./pages/blogs/blog8/blog8.component";
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    HealthcareProvidersComponent,
    TelemedicineComponent,
    BlogsComponent,
    ImpactComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    Blog1Component,
    Blog2Component,
    FaqComponent,
    Blog3Component,
    Blog4Component,
    Blog5Component,
    Blog6Component,
    Blog7Component,
    Blog8Component,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    BrowserAnimationsModule, // Add this only in the root module
    HttpClientModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbCollapseModule,
    ScrollSpyModule.forRoot(),
    ScrollToModule.forRoot(),
  ],
  providers: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
