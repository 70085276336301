import { Component, OnInit } from "@angular/core";
import * as seo_data from "../../meta_tag_info.json";
import { Meta, Title } from "@angular/platform-browser";
const dy_data = (<any>seo_data).impact;

@Component({
  selector: "app-impact",
  templateUrl: "./impact.component.html",
  styleUrls: ["./impact.component.css"],
})
export class ImpactComponent implements OnInit {
  stats1_texts = [
    "I got to know more about my lifestyle habits and how to maintain my sugar levels through this programme.",
    "It is a very motivativing app. Very helpful for me to control my sugars as I am pre diabetic",
  ];
  stats1_text: any;
  counter = 0;
  constructor(private _title: Title, private _meta: Meta) {}

  ngOnInit() {
    this._title.setTitle(dy_data.title);
    this._meta.updateTag({
      name: dy_data.meta_info.desc.name,
      content: dy_data.meta_info.desc.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.keywords.name,
      content: dy_data.meta_info.keywords.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.robots.name,
      content: dy_data.meta_info.robots.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.tc.name,
      content: dy_data.meta_info.tc.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.td.name,
      content: dy_data.meta_info.td.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.tt.name,
      content: dy_data.meta_info.tt.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.ti.name,
      content: dy_data.meta_info.ti.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogl.property,
      content: dy_data.meta_info.ogl.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogt.property,
      content: dy_data.meta_info.ogt.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogti.property,
      content: dy_data.meta_info.ogti.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogd.property,
      content: dy_data.meta_info.ogd.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogu.property,
      content: dy_data.meta_info.ogu.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogi.property,
      content: dy_data.meta_info.ogi.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogim.property,
      content: dy_data.meta_info.ogim.content,
    });

    this.stats1_text = this.stats1_texts[0];
  }

  toggleStatsText() {
    this.counter++;
    this.stats1_text = this.stats1_texts[this.counter % 2];
  }
}
