import { Component, OnInit } from "@angular/core";
import { UserService } from "../../_services/user.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import * as seo_data from "../../meta_tag_info.json";
import { Meta, Title } from "@angular/platform-browser";
const dy_data = (<any>seo_data).home;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  contactforms;
  emailRegex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
  phoneRegex = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
  // phoneRegex = /^\d{9}$/;
  nameRegex = /^[a-z ,.'-]+$/i;
  formResponse = "";
  loading = false;

  constructor(
    private userService: UserService,
    private _title: Title,
    private _meta: Meta
  ) {}

  ngOnInit() {
    this._title.setTitle(dy_data.title);
    this._meta.updateTag({
      name: dy_data.meta_info.desc.name,
      content: dy_data.meta_info.desc.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.keywords.name,
      content: dy_data.meta_info.keywords.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.robots.name,
      content: dy_data.meta_info.robots.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.tc.name,
      content: dy_data.meta_info.tc.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.td.name,
      content: dy_data.meta_info.td.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.tt.name,
      content: dy_data.meta_info.tt.content,
    });
    this._meta.updateTag({
      name: dy_data.meta_info.ti.name,
      content: dy_data.meta_info.ti.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogl.property,
      content: dy_data.meta_info.ogl.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogt.property,
      content: dy_data.meta_info.ogt.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogti.property,
      content: dy_data.meta_info.ogti.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogd.property,
      content: dy_data.meta_info.ogd.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogu.property,
      content: dy_data.meta_info.ogu.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogi.property,
      content: dy_data.meta_info.ogi.content,
    });
    this._meta.updateTag({
      property: dy_data.meta_info.ogim.property,
      content: dy_data.meta_info.ogim.content,
    });

    // contact form
    this.contactforms = new FormGroup({
      radio: new FormControl("", Validators.compose([Validators.required])),
      name: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.nameRegex),
        ])
      ),
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailRegex),
        ])
      ),
      mobile: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.phoneRegex),
        ])
      ),
      text: new FormControl(""),
    });
  }

  get f() {
    return this.contactforms.controls;
  }

  onValidValue(id) {
    console.log("valid value in form", id);
    // document.getElementById(id).setAttribute('class', 'form-control.is-valid');
    // console.log(el);
  }

  contactFormData(dataToSend) {
    this.loading = true;
    // console.log('contact form data', dataToSend);
    dataToSend["radio-group"] = dataToSend["radio"];
    delete dataToSend["radio"];
    // console.log('contact form data', dataToSend);

    this.userService.contactform(dataToSend).subscribe(
      (data) => {
        // console.log('contact form on submit', data);
        this.formResponse = "Success";
        this.loading = false;
      },
      (error) => {
        this.formResponse = "Error";
        this.loading = false;
      }
    );
  }
}
